window.addEventListener("turbolinks:load", () => {
  const input = document.querySelector("#post-content-input");
  const counter = document.querySelector("#post-content-count");

  if (!!input) {
    input.addEventListener("input", (e) => {
      const inputValue = e.target.value
      const count = [...inputValue].length
      counter.textContent = count;

      const isRed = counter.classList.contains("color-red");
      if (count > 300 && !isRed) {
        counter.classList.add("color-red");
      } else if (count <= 300 && isRed) {
        counter.classList.remove("color-red");
      }
    });
  }
});
