/* --------------------------------------- */
/* スライド */
/* --------------------------------------- */

import Swiper, { Navigation, Pagination } from 'swiper'
// import 'swiper/css'
document.addEventListener("DOMContentLoaded", function () { //発火タイミングを指定
  // スライドの数を決定するためにウィンドウ幅を取得する
  var wide = window.innerWidth;
  var slidercount;
  if (wide > 767) {
    slidercount = 3;
  } else {
    slidercount = 1;
  }
  var mySwiper_member = new Swiper(".slider", {
    // 詳細設定
    loop: true, // 連続でスライド可能にするか。
    speed: 800, // スライドが切り替わる時間(ミリ秒)。
    slidesPerView: slidercount, // スライドを一度に何枚表示するか
    spaceBetween: 0, // スライド間の余白(ピクセル)
    direction: "horizontal", // effectがslideの場合のスライドする方向。 'horizontal'(水平) or 'vertical'(垂直)。
    effect: "slide", // "slide" or "fade"(フェード) or "cube"(キューブ回転) or "coverflow"(カバーフロー) or "flip"(平面回転)。

    // スライダーの自動再生
    // autoplay: trueのみなら既定値での自動再生
    // autoplay: false,
    autoplay: {
      delay: 2000, // スライドが切り替わるまでの表示時間(ミリ秒)
      stopOnLast: false, // 最後のスライドまで表示されたら自動再生を中止するか
      disableOnInteraction: false, // ユーザーのスワイプ操作を検出したら自動再生を中止するか
    },

    // ページネーションを有効化
    pagination: {
      el: ".slider__pagination",
      type: "bullets",
      clickable: true,
    },

    // ナビゲーションを有効化
    navigation: {
      nextEl: ".slider__button-next",
      prevEl: ".slider__button-prev",
    },
  });
});
